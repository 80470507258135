iframe#webpack-dev-server-client-overlay {
    display: none !important
}

body {
    background: rgb(17 17 17);
}

.container-fluid {
    padding-left: 0;
    padding-right: 0;
}

.row {
    margin-left: 0;
    margin-right: 0;
}

.main-editor {
    padding-left: 0;
    padding-right: 0;
}

/* css for resizable */
.editor {
    height: calc(100vh - 106px);
    width: 100vw;
}

.pane-second {
    height: calc(100vh - 106px);
}

.action-sash-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(#000, .1);
}

/* css for resizable ends */

/* tuf heading navbar (main navbar) CSS */
.tuf-heading-container {
    background: #202020;
    display: flex;
}

/* CSS for takuforward sheets on the right */
.tuf-heading-sheets {
    /* z-index: 100000000; */
    margin-left: auto;
    padding-top: 5px;
    padding-bottom: 7px;
    padding-left: 0.2rem;
    padding-right: 1rem;
    display: flex;
    align-items: center;
}

.navbar-dark .navbar-nav .nav-link {
    color: #fff;
    font-family: Amaranth;
}

/* CSS for takuforward main heading */
.tuf-heading-navbar {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    padding-top: 5px;
    padding-bottom: 7px;
    padding-left: 0.2rem;
    padding-right: 1rem;
    margin-left: 1rem;
    color: white;
    text-decoration: none;
    width: fit-content;
}


.tuf-heading-navbar :hover {
    color: white;
}

.tuf-logo {
    height: 48px;
}

.tuf-heading {
    margin: 0;
    color: white;
    font-family: Amaranth;
    font-size: 2rem;
}

/* CSS for header panel of body */
.header-panel {
    height: 106px;
    padding-left: 0;
    padding-right: 0;
}

.navbar-container {
    height: 40px;
    padding: 0;
    background: #272727;
    padding-left: 0;
    padding-right: 0;
}

.navbar-options {
    padding: 0 15px 0 15px;
    height: 40px;
    display: flex;
    gap: 2px;
    width: 66.59vw;
}

.navbar-right-heading {
    margin-left: auto;
    margin-right: 1rem;
    color: white;
    display: flex;
    align-items: center;
}

.navbar-right-heading span {
    font-size: 14px;
    font-weight: 550;
    margin-right: 5px;
    margin-left: 5px;
}

.navbar-right-heading img {
    height: 2rem;
}

/* CSS for language dropdown & run button */

.run-btn {
    max-width: max-content;
    color: #fff;
    display: flex;
    align-items: center;
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
    margin-left: auto;
    /* auto */
    margin-right: 0.3rem;
    padding: 0 .5rem 0 .5rem;
    background: #e31d3b;
    border-radius: 5px;
    cursor: pointer;
}

.run-btn label{
    display: flex;
}

.run-btn span {
    font-size: 13.5px;
    font-weight: 500;
    font-family: Rubik, sans-serif;
}

.not-clickable {
    pointer-events: none;
    opacity: 0.6;
}

/*------------------------------------------------------------------------ CSS for dropdowns */
.dropdown-menu-container {
    height: 100%;
    width: fit-content;
    font-family: Rubik, Sans-serif;
}

.dropdown-trigger {
    height: 100%;
    /* border-right: 2px solid #4e4e4e; */
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: 500;
    font-size: 0.875rem;
    overflow: hidden;
}

.dropdown-trigger :hover {
    color: red;
}


.dropdown-menu-items {
    position: relative;
    z-index: 1000;
    background: #fff;
    border-radius: 5px;
    width: fit-content;
}

.dropdown-menu-items ul {
    padding: 0;
    list-style-type: none;
}

.dropdown-menu-items li {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 9px;
    padding-bottom: 5px;
    font-family: Rubik, Sans-serif;
}

.dropdown-menu-items.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition: 0.5s ease;
}

.dropdown-menu-items.inactive {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    transition: 0.5s ease;
}

.custom-dropdown-item {
    font-family: Rubik, Sans-serif;
    font-weight: 500;
    font-size: 0.9rem;
    color: rgb(71 85 105);
    cursor: pointer;
}

.custom-dropdown-item:hover {
    border-radius: 5px;
    color: black;
}

.custom-dropdown-item:active {
    color: rgb(71 85 105);
    background: #bdbdbd;
}

/* ---------------------------------------------------------------media query needed----------------- */
/*------------------------------------------------ CSS for code-download-btn under File dropdown */
#file-btn {
    color: rgb(71 85 105);
}

#file-btn:hover {
    text-decoration: none;
    color: black;
}

#file-btn:active {
    color: rgb(71 85 105);
}

/* ---------------------------------------------------------------CSS for settings pop-up */

.modal-wrapper {
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: transparent;
}

.modal-container {
    position: fixed;
    top: 50%;
    left: 50%;
    background: #fff;
    transform: translate(-50%, -50%);
    font-family: Rubik, Sans-serif;
    border-radius: 8px;
    max-width: 25rem;
    width: 75%;
    box-shadow: 0 0.20rem 0.30rem rgba(0, 0, 0, .15) !important;
}

.modal-container-heading {
    max-height: max-content;
    background: rgb(208, 216, 228);
    padding: 16px 24px 16px 24px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.modal-container-heading p {
    height: 28px;
    color: rgb(39 48 63);
    line-height: 28px;
    font-size: 18px;
    font-weight: 550;
    margin: 0;
}

.modal-container-body {
    padding: 16px 24px 16px 24px;
}

.modal-container-body p {
    margin: 0;
    color: rgb(54 65 82);
    font-size: 16px;
    line-height: 28px;
    font-weight: 500;
}

.container-body-row {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.modal-container-footer {
    padding: 0 1rem 0.5rem 1rem;
}

.modal-container-footer-btn {
    padding: 0.5rem 0.5rem;
    font-size: 18px;
    display: flex;
    gap: 5px;
    align-items: center;
    cursor: pointer;
    max-width: max-content;
    border-radius: 5px;
    background: rgb(208, 216, 228);
}

.modal-container-footer-btn p {
    margin: 0;
}

/* CSS for dropdown button inside settings pop up */

.btn-outline-primary {
    border-color: rgb(202, 212, 224);
    color: rgb(54 65 82);
    font-size: 14px;
    font-weight: 400;
    font-family: sans-serif;
}

#dropdownMenuButton.btn-outline-primary.dropdown-toggle:active {
    color: rgb(54 65 82);
    background: transparent;
}

#dropdownMenuButton.btn-outline-primary.dropdown-toggle.btn:focus {
    color: rgb(54 65 82);
    background: transparent;
    box-shadow: none;
}

#dropdownMenuButton.btn-outline-primary.dropdown-toggle:hover {
    background: transparent;
    color: rgb(54 65 82);
}

.dropdown-item {
    cursor: pointer;
    font-weight: 200;
    font-family: sans-serif;
    font-size: 14px;
}


/* ---------------------------------------------------------------CSS for input panel */
.input-panel {
    background: #202020;
    height: calc(100% - 3px);
    margin-bottom: 5px;
}

.panel-heading {
    display: flex;
    /* align-items: start; */
    height: 2.5rem;
    padding: 0 15px 8px 15px;
    background: #202020;
    color: #fff;
    font-size: 16px;
    font-family: Nunito, Helvetica Neue, Helvetica, Arial, sans-serif;
}

.panel-heading-text {
    padding: 4px 24px;
    background: rgb(17 17 17);
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
    font-weight: 700;
    box-shadow: 0 0.20rem 0.30rem rgba(0, 0, 0, .15) !important;
}

.panel-heading-icon {
    padding: 8px 10px;
    background: rgb(17 17 17);
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
    font-weight: 500;
    box-shadow: 0 0.20rem 0.30rem rgba(0, 0, 0, .15) !important;
}

.input-textbox {
    padding: 0.375rem 0.375rem 0.375rem 1rem;
    margin: 0;
    border-radius: 0;
    height: calc(100% - 50px);
    width: calc(100% - 5px);
    overflow: auto;
    background: #202020;
    border: none;
    border-right: 1px solid #272727;
    color: #fff !important;
    resize: none;
    outline: none;
    box-shadow: none;
    font-family: Nunito, Helvetica Neue, Helvetica, Arial, sans-serif;
}

.panel-first-icon {
    margin-left: auto;
    cursor: pointer;
}


.panel-first-icon-label {
    margin-bottom: 0;
    cursor: pointer;
}

.panel-second-icon {
    margin-left: 0.5rem;
    cursor: pointer;
}

.panel-third-icon {
    margin-left: 0.3rem;
    cursor: pointer;
}

/*--------------------------------------------------------------- CSS for output panel */
.output-panel {
    height: 100%;
    background: #202020;
    margin-top: 3px;
}

.panel-outputbox {
    height: calc(100% - 50px);
    background: #202020;
    border-right: 1px solid #272727;
    padding-bottom: 5px;
    color: #fff;
}

.output-text {
    padding: 0.375rem 0.375rem 0.375rem 1rem;
    margin: 0;
    border-radius: 0;
    height: 100%;
    width: calc(100% - 5px);
    overflow: auto;
    background: #202020;
    border: none;
    border-right: 1px solid #272727;
    color: #fff !important;
    resize: none;
    outline: none;
    box-shadow: none;
    font-family: Consolas, monospace;
}

/* -------------------------CSS for share button */
.share-link-heading{
    display: flex;
    justify-content: center;
    margin-top: 8px;
    cursor: pointer;
}

.share-link-window-active{
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition: 0.5s ease;
}

.share-link-window-inactive{
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    transition: 0.5s ease;    
}


.share-link-heading .cpy-btn{
    margin: 0;
    cursor: pointer;
    padding: 5px;
    border-radius: 5px;
    background: #ff6347;
}
.share-link-heading :hover{
    /* background: rgb(208, 216, 228); */
    background: #ff4500;
    /* color: white; */
}
.share-link-window{
    background: rgb(208, 216, 228);
    height: 40px;
    padding-top: 8px;
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 8px;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
}

.share-link{
    font-size: 15px;
    color: black;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 5px;
}

@media screen and (max-width: 910px) {
    .navbar-right-tuf {
        display: none;
    }
}

@media screen and (max-width: 767px) {
    .input-panel {
        margin-top: 5px;
    }

    .navbar-options {
        width: 100vw;
        padding: 0 5px;
    }

    .navbar-right-heading {
        display: none;
    }

    .main-editor {
        padding-right: 10px;
    }
}

@media screen and (max-width: 333px) {
    .run-btn {
        background: #e31d3b;
        border-radius: 8px;
    }

    .run-btn span {
        display: none;
    }
}

@media screen and (max-width: 999px) {
    .input-panel {
        height: 60%;
    }

    .output-panel {
        height: 40%;
    }

    .main-editor {
        background: #202020;
    }
}


